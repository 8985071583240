import { useEffect, useMemo, useState, useRef } from "react";
import { createConsumer } from "@rails/actioncable";

const log = (x) => {
  if (x.verbose) console.log({[x.type]: `useActionCable: ${x.message}`});
};

export function useActionCable(url, { verbose } = { verbose: true }) {
  const actionCable = useMemo(() => createConsumer(url), [url]);
  useEffect(() => {
    log({
      verbose: verbose,
      type: "info",
      message: "Created Action Cable",
    });
    return () => {
      log({
        verbose: verbose,
        type: "info",
        message: "Disconnected Action Cable",
      });
      actionCable.disconnect();
    };
  }, []);
  return {
    actionCable,
  };
}

export function useChannel(actionCable, { verbose } = { verbose: true }) {
  const [connected, setConnected] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const channelRef = useRef();
  useEffect(() => {
    return () => {
      unsubscribe();
    };
  }, []);

  const subscribe = (data, callbacks) => {
    log({
      verbose: verbose,
      type: "info",
      message: `Connecting to ${data.channel}`,
    });
    const channel = actionCable.subscriptions.create(data, {
      received: (x) => {
        log({
          verbose: verbose,
          type: "info",
          message: `Received ${JSON.stringify(x)}`,
        });
        if (callbacks.received) callbacks.received(x);
      },
      initialized: () => {
        log({
          verbose: verbose,
          type: "info",
          message: `Init ${data.channel}`,
        });
        setSubscribed(true);
        if (callbacks.initialized) callbacks.initialized();
      },
      connected: () => {
        log({
          verbose: verbose,
          type: "info",
          message: `Connected to ${data.channel}`,
        });
        setConnected(true);
        if (callbacks.connected) callbacks.connected();
      },
      disconnected: () => {
        log({
          verbose: verbose,
          type: "info",
          message: `Disconnected`,
        });
        setConnected(false);
        if (callbacks.disconnected) callbacks.disconnected();
      },
    });
    channelRef.current = channel;
  };

  const unsubscribe = () => {
    setSubscribed(false);

    if (channelRef.current) {
      log({
        verbose: verbose,
        type: "info",
        // @ts-ignore
        message: `Unsubscribing from ${channelRef.current.identifier}`,
      });
      actionCable.subscriptions.remove(channelRef.current);
      // @ts-ignore
      channelRef.current = null;
    }
  };

  const perform = (action, payload) => {
    if (subscribed && !connected) throw "useActionCable: not connected";
    if (!subscribed) throw "useActionCable: not subscribed";
    try {
      log({
        verbose: verbose,
        type: "info",
        message: `Sending ${action} with payload ${JSON.stringify(payload)}`,
      });
      // @ts-ignore
      channelRef.current.perform(action, payload);
    } catch {
      throw "useActionCable: Unknown error";
    }
  };

  const send = ({ action, payload }) => {
    perform(action, payload);
  };

  return {
    subscribe,
    unsubscribe,
    send,
  };
}
