const Home = ({ classes }) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.00991 0.0427151C8.54953 0.194937 8.63863 0.109545 4.43209 4.30866C2.2193 6.51775 0.355497 8.41125 0.284955 8.5152C0.00278606 8.93474 -0.0788944 9.57334 0.0807537 10.056C0.322082 10.7726 0.893845 11.1958 1.71436 11.2626L2.00767 11.2886L2.01881 14.3182C2.02995 17.2884 2.03366 17.3515 2.10791 17.552C2.38637 18.2872 2.85046 18.7401 3.5299 18.9406C3.70439 18.9926 3.98285 19 5.51621 19C7.2315 19 7.30576 18.9963 7.42457 18.9257C7.4914 18.8849 7.57679 18.7995 7.61763 18.7327C7.68817 18.6139 7.69188 18.5396 7.69188 16.2526C7.69188 14.6449 7.70674 13.8467 7.73272 13.7465C7.79584 13.5237 7.97034 13.2972 8.16712 13.1821L8.34161 13.0782H9.51484H10.6881L10.87 13.1896C11.0742 13.3158 11.2673 13.6017 11.3081 13.8281C11.3192 13.9061 11.3304 15.0088 11.3304 16.2823C11.3304 18.7884 11.323 18.6918 11.5346 18.8886L11.6348 18.9814L13.4207 18.9926C14.6162 19 15.2733 18.9889 15.4033 18.9629C15.9156 18.8552 16.5097 18.4022 16.7436 17.9419C17.0035 17.4221 16.9886 17.6337 17.0035 14.2997L17.0146 11.2849L17.319 11.2589C18.0653 11.1958 18.5554 10.8691 18.8635 10.2379C18.9935 9.96317 18.9972 9.95204 18.9972 9.49537C18.9972 9.03499 18.9972 9.02756 18.8561 8.73425C18.715 8.43724 18.7113 8.43352 14.6421 4.36435C10.5693 0.29147 10.5693 0.29147 10.2722 0.150385C9.99007 0.0167255 9.95295 0.00930023 9.56682 0.00187492C9.30322 -0.00555038 9.10644 0.00930023 9.00991 0.0427151Z" />
  </svg>
);

const Profile = ({ classes }) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
      fill="#18333E"
    />
    <path
      d="M14.08 14.15C11.29 12.29 6.73996 12.29 3.92996 14.15C2.65996 15 1.95996 16.15 1.95996 17.38C1.95996 18.61 2.65996 19.75 3.91996 20.59C5.31996 21.53 7.15996 22 8.99996 22C10.84 22 12.68 21.53 14.08 20.59C15.34 19.74 16.04 18.6 16.04 17.36C16.03 16.13 15.34 14.99 14.08 14.15Z"
      fill="#18333E"
    />
    <path
      d="M19.9899 7.33999C20.1499 9.27999 18.7699 10.98 16.8599 11.21C16.8499 11.21 16.8499 11.21 16.8399 11.21H16.8099C16.7499 11.21 16.6899 11.21 16.6399 11.23C15.6699 11.28 14.7799 10.97 14.1099 10.4C15.1399 9.47999 15.7299 8.09999 15.6099 6.59999C15.5399 5.78999 15.2599 5.04999 14.8399 4.41999C15.2199 4.22999 15.6599 4.10999 16.1099 4.06999C18.0699 3.89999 19.8199 5.35999 19.9899 7.33999Z"
      fill="#18333E"
    />
    <path
      d="M21.9902 16.59C21.9102 17.56 21.2902 18.4 20.2502 18.97C19.2502 19.52 17.9902 19.78 16.7402 19.75C17.4602 19.1 17.8802 18.29 17.9602 17.43C18.0602 16.19 17.4702 15 16.2902 14.05C15.6202 13.52 14.8402 13.1 13.9902 12.79C16.2002 12.15 18.9802 12.58 20.6902 13.96C21.6102 14.7 22.0802 15.63 21.9902 16.59Z"
      fill="#18333E"
    />
  </svg>
);

const Messanger = ({ classes = "" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={classes}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 3H7C4 3 2 4.5 2 8V15C2 18.5 4 20 7 20H10.57C11.16 20 11.64 19.48 11.56 18.89C11.46 18.18 11.48 17.44 11.63 16.68C12.16 14.08 14.3 12.01 16.92 11.58C18.23 11.37 19.48 11.55 20.58 12.03C21.25 12.32 22 11.86 22 11.13V8C22 4.5 20 3 17 3ZM17.47 8.59L14.34 11.09C13.68 11.62 12.84 11.88 12 11.88C11.16 11.88 10.31 11.62 9.66 11.09L6.53 8.59C6.21 8.33 6.16 7.86 6.41 7.53C6.67 7.21 7.14 7.15 7.46 7.41L10.59 9.91C11.35 10.52 12.64 10.52 13.4 9.91L16.53 7.41C16.85 7.15 17.33 7.2 17.58 7.53C17.84 7.86 17.79 8.33 17.47 8.59Z" />
    <path d="M18 13C15.24 13 13 15.23 13 18C13 20.76 15.24 23 18 23C20.77 23 23 20.76 23 18C23 15.23 20.77 13 18 13ZM20.05 17.55L19.68 17.91L17.5 20.1C17.4 20.19 17.21 20.29 17.07 20.31L16.09 20.45C15.74 20.5 15.49 20.25 15.54 19.9L15.68 18.92C15.7 18.78 15.79 18.59 15.89 18.49L18.07 16.32L18.43 15.95C18.67 15.71 18.94 15.55 19.22 15.55C19.46 15.55 19.73 15.66 20.03 15.95C20.7 16.6 20.49 17.11 20.05 17.55Z" />
  </svg>
);

const Settings = ({ classes = "" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 21 20"
    className={classes}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.1 7.22C16.29 7.22 15.55 5.94 16.45 4.37C16.97 3.46 16.66 2.3 15.75 1.78L14.02 0.789997C13.23 0.319997 12.21 0.599998 11.74 1.39L11.63 1.58C10.73 3.15 9.25 3.15 8.34 1.58L8.23 1.39C7.78 0.599998 6.76 0.319997 5.97 0.789997L4.24 1.78C3.33 2.3 3.02 3.47 3.54 4.38C4.45 5.94 3.71 7.22 1.9 7.22C0.86 7.22 0 8.07 0 9.12V10.88C0 11.92 0.85 12.78 1.9 12.78C3.71 12.78 4.45 14.06 3.54 15.63C3.02 16.54 3.33 17.7 4.24 18.22L5.97 19.21C6.76 19.68 7.78 19.4 8.25 18.61L8.36 18.42C9.26 16.85 10.74 16.85 11.65 18.42L11.76 18.61C12.23 19.4 13.25 19.68 14.04 19.21L15.77 18.22C16.68 17.7 16.99 16.53 16.47 15.63C15.56 14.06 16.3 12.78 18.11 12.78C19.15 12.78 20.01 11.93 20.01 10.88V9.12C20 8.08 19.15 7.22 18.1 7.22ZM10 13.25C8.21 13.25 6.75 11.79 6.75 10C6.75 8.21 8.21 6.75 10 6.75C11.79 6.75 13.25 8.21 13.25 10C13.25 11.79 11.79 13.25 10 13.25Z" />
  </svg>
);

const SadSmile = ({ classes = "" }) => (
  <svg className={classes} width="24" height="24" viewBox="0 0 24 24">
    <path d="M9 11C9.55228 11 10 10.5523 10 10C10 9.44772 9.55228 9 9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11Z" />
    <path d="M14 17C14 15.8954 13.1046 15 12 15C10.8954 15 10 15.8954 10 17H8C8 14.7909 9.79086 13 12 13C14.2091 13 16 14.7909 16 17H14Z" />
    <path d="M16 10C16 10.5523 15.5523 11 15 11C14.4477 11 14 10.5523 14 10C14 9.44772 14.4477 9 15 9C15.5523 9 16 9.44772 16 10Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
    />
  </svg>
);

const MediumSmile = ({ classes = "" }) => (
  <svg className={classes} width="24" height="24" viewBox="0 0 24 24">
    <path d="M8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11H9C9.55228 11 10 10.5523 10 10C10 9.44772 9.55228 9 9 9H8Z" />
    <path d="M15 9C14.4477 9 14 9.44772 14 10C14 10.5523 14.4477 11 15 11H16C16.5523 11 17 10.5523 17 10C17 9.44772 16.5523 9 16 9H15Z" />
    <path d="M9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15H9Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
    />
  </svg>
);

const HappySmile = ({ classes = "" }) => (
  <svg
    className={classes}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>smile</title>
    <desc>Created with sketchtool.</desc>
    <g
      id="people"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="smile" fill="#000000">
        <path
          d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M16,13 C16,15.209139 14.209139,17 12,17 C9.790861,17 8,15.209139 8,13 L16,13 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M9.5,11 C10.3284271,11 11,10.3284271 11,9.5 C11,8.67157288 10.3284271,8 9.5,8 C8.67157288,8 8,8.67157288 8,9.5 C8,10.3284271 8.67157288,11 9.5,11 Z M14.5,11 C15.3284271,11 16,10.3284271 16,9.5 C16,8.67157288 15.3284271,8 14.5,8 C13.6715729,8 13,8.67157288 13,9.5 C13,10.3284271 13.6715729,11 14.5,11 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);

const Time = ({ classes }) => (
  <svg
    className={classes}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z" />
    </g>
  </svg>
);

const Man = ({ classes }) => (
  <svg width="20" height="20" viewBox="0 0 512 512" className={classes}>
    <g>
      <g>
        <g>
          <path
            d="M256,238.428c65.734,0,119.214-53.48,119.214-119.214S321.734,0,256,0c-65.735,0-119.214,53.48-119.214,119.214
				S190.265,238.428,256,238.428z"
          />
          <path
            d="M256,253.693c-97.918,0-177.58,79.662-177.58,177.58v61.375c0,10.687,8.664,19.351,19.351,19.351h316.458
				c10.687,0,19.351-8.664,19.351-19.351v-61.375C433.58,333.355,353.918,253.693,256,253.693z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

const Moon = ({ classes = "" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 20 20"
    className={classes}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.5302 13.93C19.3702 13.66 18.9202 13.24 17.8002 13.44C17.1802 13.55 16.5502 13.6 15.9202 13.57C13.5902 13.47 11.4802 12.4 10.0102 10.75C8.7102 9.29999 7.9102 7.40999 7.9002 5.36999C7.9002 4.22999 8.1202 3.12999 8.5702 2.08999C9.0102 1.07999 8.7002 0.549994 8.4802 0.329994C8.2502 0.0999944 7.7102 -0.220006 6.6502 0.219994C2.5602 1.93999 0.0301965 6.03999 0.330197 10.43C0.630197 14.56 3.5302 18.09 7.3702 19.42C8.2902 19.74 9.2602 19.93 10.2602 19.97C10.4202 19.98 10.5802 19.99 10.7402 19.99C14.0902 19.99 17.2302 18.41 19.2102 15.72C19.8802 14.79 19.7002 14.2 19.5302 13.93Z" />
  </svg>
);

const Controller = ({ classes }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 360 270"
    className={classes}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m155.28 155.51c-2.573-9.212-9.944-16.461-19.301-18.981v18.981h19.3z" />
      <path d="m353.93 120.47c-8.85-46.19-29.57-121.94-82.05-120.45-33.922 0.966-26.277 35.779-83.655 30.111-0.809 1.224-13.834 1.224-14.643 0-57.376 5.668-49.734-29.145-83.655-30.111-52.48-1.494-73.198 74.26-82.047 120.45-11.283 58.903-18.08 171.4 48.263 145.8 7.434-2.867 91.267-54.054 101.35-54.054h46.818c10.086 0 93.924 51.17 101.35 54.054 63.87 24.79 59.55-86.9 48.27-145.8zm-298.3-24.09c0-14.299 11.767-25.892 26.282-25.892 14.517 0 26.283 11.593 26.283 25.892s-11.766 25.892-26.283 25.892c-14.518 0-26.285-11.59-26.285-25.89zm101.17 72.44l-0.308 1.175c-2.662 9.815-10.533 17.528-20.517 20.096l-1.784 0.421c-1.771 0.337-3.596 0.528-5.465 0.528-1.871 0-3.694-0.191-5.465-0.528l-1.985-0.471c-9.89-2.62-17.673-10.299-20.315-20.046l-0.509-2.19c-0.308-1.659-0.493-3.36-0.493-5.105 0-1.746 0.185-3.447 0.493-5.106l0.48-2.079c2.614-9.801 10.416-17.525 20.344-20.156l1.414-0.359c1.948-0.411 3.964-0.64 6.036-0.64 2.071 0 4.087 0.229 6.036 0.64l1.213 0.308c10.023 2.578 17.914 10.341 20.545 20.207l0.279 1.066c0.442 1.973 0.694 4.015 0.694 6.119 0 2.1-0.25 4.15-0.69 6.12zm120.17-93.674c5.706 0 10.331 4.557 10.331 10.177 0 5.622-4.625 10.178-10.331 10.178-5.704 0-10.33-4.556-10.33-10.178 0-5.62 4.62-10.177 10.33-10.177zm-43.49 111.57c-14.496 0-26.246-11.578-26.246-25.858 0-14.281 11.75-25.86 26.246-25.86 14.5 0 26.251 11.579 26.251 25.86 0 14.28-11.75 25.86-26.25 25.86zm22.83-70.86c-5.706 0-10.331-4.557-10.331-10.179 0-5.621 4.625-10.177 10.331-10.177 5.705 0 10.33 4.556 10.33 10.177 0 5.62-4.63 10.18-10.33 10.18zm20.66 20.35c-5.704 0-10.33-4.557-10.33-10.177s4.626-10.177 10.33-10.177c5.706 0 10.331 4.557 10.331 10.177s-4.63 10.18-10.33 10.18zm20.66-20.35c-5.704 0-10.329-4.556-10.329-10.177s4.625-10.178 10.329-10.178c5.706 0 10.333 4.557 10.333 10.178s-4.63 10.18-10.33 10.18z" />
      <path d="m135.98 188.87c9.32-2.511 16.671-9.713 19.272-18.871h-19.272v18.87z" />
      <path d="m102.21 170c2.582 9.093 9.845 16.262 19.07 18.82v-18.82h-19.07z" />
      <path d="m121.28 136.58c-9.262 2.569-16.546 9.784-19.1 18.929h19.1v-18.93z" />
    </g>
  </svg>
);

export const Icons = {
  Time,
  SadSmile,
  MediumSmile,
  HappySmile,
  Home,
  Profile,
  Messanger,
  Settings,
  Man,
  Moon,
  Controller,
};
